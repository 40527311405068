import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
//
import Home from "./home";
import LoginComp from "./login/loginComp";
import ExamComp from "./ExamComp";
import ExamAdmin from "./admin/ExamAdmin";
import Result from "./result/ResultMain";
import HeadAuth from "./HeadAuth";
import Error from "./error";
//
import Monitor from "./admin/monitor";
//
ReactDOM.render(
	<BrowserRouter>
		<Switch>
			<Route path="/" component={Home} exact />
			<Route path="/profile/" render={() => <LoginComp isHome={true} />} />
			<Route path="/test/" component={ExamComp} />
			<Route path="/testAdmin/" component={ExamAdmin} />
			<Route path="/result/" component={Result} />
			<Route path="/monitor/" component={Monitor} />
			<Route path="/helloHead/" component={HeadAuth} />
			<Route component={Error} />
		</Switch>
	</BrowserRouter>,
	document.getElementById("root")
);
