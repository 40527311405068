import React, { Component } from "react";
class Error extends Component {
  render() {
    return (
      <center>
        <h1>No such Page Exists</h1>
      </center>
    );
  }
}
export default Error;
