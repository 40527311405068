// Id : Language
const langName = {
	0: "All",
	11: "C",
	41: "C++ 4.3.2",
	1: "C++ [GCC]",
	44: "C++14 [GCC]",
	27: "C# [Mono]",
	10: "Java",
	55: "Java- legacy",
	35: "JavaScript (Rhino)",
	112: "JavaScript [SpiderMonkey]",
	47: "Kotlin",
	56: "Node.js",
	43: "Objective-C",
	29: "PHP",
	22: "Pascal [FPC]",
	2: "Pascal [GPC]",
	3: "Perl",
	99: "Python (Pypy)",
	4: "Python 2.x [Pypy]",
	116: "Python 3.x",
	117: "R",
	17: "Ruby",
	93: "Rust",
	52: "SQLite - queries",
	40: "SQLite - schema",
	39: "Scala",
	85: "Swift",
	57: "TypeScript",
};
const edtrMode = {
	0: "c_cpp",
	11: "c_cpp",
	41: "c_cpp",
	1: "c_cpp",
	44: "c_cpp",
	27: "csharp",
	10: "java",
	55: "java",
	35: "javascript",
	112: "javascript",
	47: "kotlin",
	56: "javascript",
	43: "objectivec",
	29: "php",
	22: "pascal",
	2: "pascal",
	3: "perl",
	99: "python",
	4: "python",
	116: "python",
	117: "r",
	17: "ruby",
	93: "rust",
	52: "sql",
	40: "sql",
	39: "scala",
	85: "swift",
	57: "typescript",
};
// Compiler Version
const langV = {
	0: { 0: "All" },
	11: {
		1: "gcc 5.1.1",
		2: "gcc 7.2.1",
		3: "gcc 8.3",
		5: "gcc 9.3",
		6: "gcc 10.2",
	},
	41: { 1: "gcc 4.3.2" },
	1: { 1: "5.1.1", 2: "7.3", 3: "8.3", 5: "9.3", 6: "10.2" },
	44: {
		1: "5 5.1.1",
		3: "8.3",
		5: "9.3",
		6: "10.2",
	},
	27: {
		1: "Mono 4.0.2",
		2: "gmcs 5.12",
		3: "gmcs 5.20.1",
		5: "gmcs 6.8",
		6: "gmcs 6.12",
	},
	10: {
		1: "jdk 8u51",
		2: "HotSpot 10.0.1",
		3: "HotSpot 12",
		5: "HotSpot 14",
		6: "HotSpot 15",
	},
	55: {
		1: "HotSpot 8u181",
		5: "sun-jdk-1.7.0_10",
		6: "HotSpot 8u271",
	},
	35: {
		1: "rhino 1.7.7",
		3: "rhino 1.7.9",
		5: "rhino 1.7.12",
		6: "rhino 1.7.13",
	},
	112: {
		1: "24.2.0",
		3: "60.2.3",
		5: "68.6.0",
		6: "78.3",
	},
	47: {
		1: "1.0.6",
		5: "1.3.71",
		6: "1.4.30",
	},
	56: {
		1: "7.4.0",
		3: "11.12.0",
		5: "13.12.0",
		6: "15.6.0",
	},
	43: {
		1: "gcc-5 5.1.1",
		3: "gcc 8.3",
		5: "gcc 9.3",
		6: "gcc 10.2",
	},
	29: {
		1: "5.6.11-1",
		2: "7.2",
		3: "7.3.5",
		5: "7.4.3",
		6: "8.0",
	},
	22: {
		1: "2.6.4+dfsg-6",
		3: "3.0.4",
		6: "3.2",
	},
	2: {
		1: "20070904",
		3: "20070904",
	},
	3: {
		1: "5.20.1",
		3: "5.28.1",
		5: "5.30.0",
		6: "5.30.3",
	},
	99: {
		1: "2.6.0",
		3: "2.7.13",
	},
	4: {
		1: "2.7.13",
		2: "cpython 2.7.15",
		3: "cpython 2.7.16",
	},
	116: {
		1: "3.5.3",
		2: "3.6.6",
		3: "3.7.3",
		5: "3.8.2",
		6: "3.9",
	},
	117: {
		1: "3.2.2",
		3: "3.5.2",
		5: "3.6.3",
		6: "4.0.2",
	},
	17: {
		1: "2.1.5",
		2: "2.5.1",
		3: "2.5.5",
		5: "2.7.1",
		6: "3.0",
	},
	93: {
		1: "1.14.0",
		3: "1.33.0",
		5: "1.42.0",
		6: "1.49.0",
	},
	52: {
		1: "3.31.1",
		6: "3.33.0",
	},
	40: {
		1: "3-3.8.7",
		3: "3.27.2",
		5: "3.31.1",
		6: "3.33.0",
	},
	39: {
		1: "2.11.7",
		2: "2.12.6",
		3: "2.12.8",
		5: "2.13.1",
		6: "2.13.4",
	},
	85: {
		1: "3.0.2",
		2: "4.0",
		3: "4.2.2",
		5: "5.2.1",
		6: "5.3.2",
	},
	57: {
		1: "3.8.3",
		3: "3.4.5",
		6: "4.1.3",
	},
};
export { langName, edtrMode, langV };
